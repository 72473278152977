import React from "react";
import PropTypes from "prop-types";

const RenderContent = ({ html, className = "" }) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

RenderContent.propTypes = {
  html: PropTypes.string.isRequired,
};

export default RenderContent;
