import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import AnimatedBlock from "../AnimatedBlock";

const ClonedCard = ({
  children,
  className = "",
  component = "div",
  ...rest
}) => {
  const cardClasses = classNames(
    `rounded-lg md:p-5 overflow-hidden z-30 isolate`,
    className
  );
  return React.createElement(
    component,
    { ...rest, className: cardClasses },
    children
  );
};

ClonedCard.propTypes = {
  children: PropTypes.element,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const Card = (props) => {
  const {
    displaySection = true,
    isAnimated = false,
    index = 1,
    duration = 1,
    shadowHover = false,
    shadowClass = "",
    ...rest
  } = props;

  const baseShadow = `h-full w-full relative isolate after:transition after:duration-500 after:translate-z-0 after:h-full after:w-full after:-z-20 after:top-0 after:my-0 after:absolute after:bg-black after:blur-[7px] after:opacity-70 after:rounded-lg after:-scale-[100%] after:content-[' '] z-0 hover:z-10 ${shadowClass}`;

  const baseSadowHover = shadowHover
    ? "hover:after:scale-60 hover:after:translate-y-10 hover:after:blur-md hover:after:opacity-40 hover:after:z-20"
    : "";

  return displaySection ? (
    isAnimated ? (
      <AnimatedBlock index={index} duration={duration}>
        <div className={classNames(baseShadow, baseSadowHover)}>
          <ClonedCard {...rest} />
        </div>
      </AnimatedBlock>
    ) : (
      <div className={classNames(baseShadow, baseSadowHover)}>
        <ClonedCard {...rest} />
      </div>
    )
  ) : null;
};

export default Card;
